/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Uno de los problemas de los controladores que hemos visto hasta ahora es que no garantizan que lleguemos al punto de referencia después de todo el transitorio, la diferencia entre la referencia y la salida una vez se ha estabilizado se llama ", React.createElement(_components.strong, null, "error en régimen permanente"), "."), "\n", React.createElement(_components.p, null, "Para eliminar este error se utiliza la acción integral, vamos a ver un caso práctico con un controlador PI, como en capítulos anteriores tenéis a vuestra disposición un pequeño script donde probar los resultados, lo podéis descargar ", React.createElement(_components.a, {
    href: "http://entramado.net/cursocontrol/ControlLearningToolbox/controlPI.m"
  }, "aquí"), "."), "\n", React.createElement(_components.p, null, "El funcionamiento del script es similar a los anteriores:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlPI</span><span class=\"token punctuation\">(</span>kp<span class=\"token punctuation\">,</span> Ti<span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "En este caso la constante que afecta al término integral es Ti y nos da una idea de en cuanto tiempo va a actuar esta acción integral, vamos a ver un ejemplo:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlPI</span><span class=\"token punctuation\">(</span><span class=\"token number\">1</span><span class=\"token punctuation\">,</span><span class=\"token number\">100</span><span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Esto nos va a dar una respuesta como la siguiente:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/74efc7f5fb19e1594549e8945c7c5b2d/b06ae/ControlPI1100.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.21518987341771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQoz52S3W7CMAyFef/nQ9quhtYWlfy1jLY4dpx4agydkNg0cS5Sp8nn2NbZiUjO2RhjrQ0hWGuHYbB3GWO897rqaQjBOTdNk4jspMoYg4giwsyllJxzqdJg2zKziMQYQwg/sHOOiESklCL1o/81KPdtyWuAiOM4rrAefBz817LElC5LXIAuV5yBZqDpigvQNSJlokyYMJW0xGUYB4XXlG/v7nhaZkjTNSqzwbPCTMQVzmmGeRiGW9mJ6fDRR0i1wvxQ51Z2ESnraLXnG5wSI8a+PwOsPXNe28oV2oL8mIuIbj3XCafD4XSD/zftn7JFyuenfRmWtnUAqIb5A9aeEfHh5aZ5/eXStl7h+7Vf9cQkfe+ru+3xeBzHsW1ba633vuu68/ncdZ0xJoTQNE0Ioe/7h541BTOrw2OMpRQAcM4xMxGltLoAAFZfpKRe3nVVzvn9fq83NimmQ3qqb98JarmnxYS+AAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Control PI con kp 1 y Ti 100.\"\n        title=\"Control PI con kp 1 y Ti 100.\"\n        src=\"/static/74efc7f5fb19e1594549e8945c7c5b2d/b06ae/ControlPI1100.png\"\n        srcset=\"/static/74efc7f5fb19e1594549e8945c7c5b2d/c26ae/ControlPI1100.png 158w,\n/static/74efc7f5fb19e1594549e8945c7c5b2d/6bdcf/ControlPI1100.png 315w,\n/static/74efc7f5fb19e1594549e8945c7c5b2d/b06ae/ControlPI1100.png 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Como podemos observar la señal de salida que en un principio parece que se va a estabilizar en 0.9 poco a poco se va corrigiendo hasta llegar a 1 de una forma suave."), "\n", React.createElement(_components.p, null, "A partir de aquí podemos empezar a jugar con nuestras constantes para obtener una buena respuesta igual que en capítulos anteriores. En el próximo vamos a ver el controlador que mejor resultados nos va a dar, el que combina la acción derivada con la integral, el PID."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
